<template>
  <div class="task-description" @paste="onPaste">
    <div class="task-description__header">
      <div class="task-description__statuses">
        <TypeTask
          v-model="setPriority"
          :list="setPriorityList"
          :isEdit="isEdit && !isSubTask"
          @input="editPriority"
          :style="'z-index: 3'"
        />
        <TypeTask
          v-model="type"
          :list="setTypeList"
          :isEdit="isEdit && !isSubTask"
          @input="editType"
          :style="'z-index: 2'"
        />
        <TypeTask
          v-model="status"
          :list="setToStatuses"
          :isEdit="isEdit && setToStatuses.length"
          @input="editStatus"
          :style="'z-index: 1'"
        />
      </div>
      <div class="task-description__title">
        <span>{{ setAlias }}-{{ currentTask.task_id }}</span>
        <EditField
          classSize="h2"
          keyUser="name"
          :maxLength="255"
          :user="setName"
          :isReadonly="!isEdit"
          @editDone="handleEditName"
          placeholder="Добавить название задачи"
        />
        <router-link
          v-if="currentTask.parent_task_name"
          class="task-description__link task-description__link_parent-name link"
          :to="setRoute"
        >
          {{ currentTask.parent_task_name }}
        </router-link>
      </div>
      <div class="task-description__dates">
        <div class="task-description__body_row">
          <DeadLineComponent
            v-model="selectDateStart"
            :value="selectDateStart"
            :label="'Дата начала'"
            :deadline="false"
            :isEdit="isEdit"
            :showCalendar="showCalendar"
            @switchCalendar="switchCalendar"
            @closeCalendar="closeCalendar"
            @clearCalendar="editDateStart"
          />
          <DeadLineComponent
            v-model="selectDateDeadline"
            :value="selectDateDeadline"
            :label="'Срок окончания'"
            :deadline="true"
            :isEdit="isEdit"
            :showCalendar="showDeadlineCalendar"
            @switchCalendar="switchDeadlineCalendar"
            @closeCalendar="closeCalendar"
            @clearCalendar="editDeadline"
          />
          <div class="deadline">
            <p class="task-description__label">Необх. время</p>
            <TimeInput
              v-model="timePlan"
              :value="timePlan"
              @input="editPlanTime"
              :readonly="!(isExecutor || isAuthor || isOwner)"
            />
          </div>
          <div class="deadline">
            <p class="task-description__label">Факт. время</p>
            <TimeInput
              v-model="timeFact"
              :value="timeFact"
              @input="editFactTime"
              :readonly="!(isOwner || isExecutor)"
            />
          </div>
        </div>
        <DeadLineCalendar
          v-model="selectDateStart"
          :value="selectDateStart"
          :amountDaysForMinDate="0"
          v-show="showCalendar"
          :minDate="false"
          @closeCalendar="editDateStart"
        />
        <DeadLineCalendar
          v-model="selectDateDeadline"
          :value="selectDateDeadline"
          :amountDaysForMinDate="0"
          v-show="showDeadlineCalendar"
          @closeCalendar="editDeadline"
        />
      </div>
    </div>
    <div class="task-description__users">
      <details open>
        <summary>
          <h3 class="task-description__subtitle">
            <i class="icon-down-secondary"></i>
            Участники
          </h3>
        </summary>
        <div class="task-description__body task-description__body_row">
          <EditableUser
            :label="'автор задачи'"
            class="task-description__owner"
            :isEditable="false"
            :user="setAuthor"
          />
          <EditableUser
            :label="'Исполнитель'"
            class="task-description__owner"
            :isEditable="isEdit"
            :items="isUsersList"
            :user="setExecutor"
            @editUser="editExecutor"
          />
        </div>
      </details>
    </div>
    <div class="task-description__others">
      <details open>
        <summary>
          <h3 class="task-description__subtitle">
            <i class="icon-down-secondary"></i>
            Описание и прочее
          </h3>
        </summary>
        <div class="task-description__body">
          <div class="task-description__wrap">
            <h4 class="task-description__caption">Проект</h4>
            <p
              class="task-description__link"
              @click="goToProject"
              :class="{ link: currentTask.project_id }"
            >
              {{ setProjectName }}
            </p>
          </div>
          <div class="task-description__wrap">
            <h4 class="task-description__caption">
              Этап
              <button
                v-if="isStageEdit"
                class="task-description__button task-description__button_edit"
                type="button"
                @click="toggleEditStage(!showEditStage)"
              >
                <i class="icon-edit" v-if="!showEditStage"></i>
                <i class="icon-cross" v-if="showEditStage"></i>
              </button>
            </h4>
            <p
              v-if="!showEditStage"
              class="task-description__link"
              @click="goToStage"
              :class="{ link: currentTask.stage_id }"
            >
              {{ setStageName }}
            </p>
            <Select
              v-if="showEditStage"
              :placeholder="'Выберите этап'"
              :items="setStageList"
              v-model="parentStage"
              :multiple="false"
              :showAvatar="false"
              :removeCross="false"
              :isLoading="stagePaginationLoader"
              @input="editStage"
              @reachLastItem="getMoreStages"
            />
          </div>
          <div class="task-description__wrap task-description__wrap_description">
            <h4 v-if="isSubTask" class="task-description__caption">Описание подзадачи</h4>
            <h4 v-else class="task-description__caption">Описание задачи</h4>
            <EditField
              classSize="text"
              keyUser="description"
              :maxLength="32000"
              :user="setDescription"
              :isReadonly="!isEdit"
              v-if="isEdit"
              @editDone="editDescription"
              :placeholder="setDescriptionPlaceholder"
            />
            <p
              v-if="!isEdit"
              class="task-description__task-description"
              ref="taskDescription"
              :style="
                bigTextTrigger
                  ? `display: -webkit-box; -webkit-line-clamp: 5; -webkit-box-orient: vertical; overflow: hidden;`
                  : ''
              "
              v-html="
                setURL(`<span>${currentTask.description ? currentTask.description : '-'}</span>`)
              "
            ></p>
            <button
              class="task-description__button"
              type="button"
              @click="showBigText"
              v-if="bigTextTrigger && !isEdit && currentTask.description"
            >
              Показать все
            </button>
            <span
              class="task-description__button"
              type="button"
              @click="hideBigText"
              v-if="!bigTextTrigger && !isEdit && currentTask.description"
              >Свернуть</span
            >
          </div>
        </div>
      </details>
    </div>
    <div class="task-description__files">
      <details open>
        <summary
          class="task-description__summary-row"
          :style="!filesList.length ? 'border-bottom: none;' : ''"
        >
          <h3 class="task-description__subtitle">
            <i class="icon-down-secondary"></i>
            Прикрепленные документы
          </h3>

          <file-upload
            v-if="filesList.length < 10 && isEdit"
            :accept="'.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .avi, .3gp, .mpeg, .mov, .mp3, .flv, .wmv'"
            post-action="/upload/post"
            class="file-uploader"
            input-id="descriptionBodyTask"
            :multiple="true"
            :drop="true"
            v-model="files"
            :drop-directory="true"
            @input="handleUploadFile"
            ref="upload"
            id="upload"
          >
            <Button
              v-if="filesList.length < 10 && isEdit"
              class="task-description__button"
              :type="'simple'"
              :icon="'plus'"
              :title="'Добавить'"
            />
          </file-upload>
        </summary>
        <div class="task-description__body" v-if="filesList.length">
          <DropFileUpload :isShow="$refs.upload && $refs.upload.dropActive" />
          <FileList :fileList="filesList" :isEdit="isEdit" />
        </div>
      </details>
    </div>
    <div class="task-description__others">
      <details open id="task-check-list">
        <summary
          class="task-description__summary-row"
          :style="
            !currentTask.checklists.length && !showCheckListForm ? 'border-bottom: none;' : ''
          "
        >
          <h3 class="task-description__subtitle">
            <i class="icon-down-secondary"></i>
            Чеклист
          </h3>
          <Button
            class="task-description__button"
            v-if="showCheckListButton"
            :type="'simple'"
            :icon="'plus'"
            :title="'Добавить'"
            @handleButton="toggleShowCheckListForm(true)"
          />
        </summary>
        <div
          class="task-description__body"
          :style="currentTask.checklists.length || showCheckListForm ? '' : 'padding: 0'"
        >
          <CheckList
            :list="currentTask.checklists"
            :showCheckListForm="showCheckListForm"
            :isEditRoles="isEdit || isParentEditUser"
            @toggleShowCheckListForm="toggleShowCheckListForm"
            @handleAccept="handleAccept"
          />
        </div>
      </details>
    </div>
    <div class="task-description__others">
      <details open id="task-marks">
        <summary
          class="task-description__summary-row"
          :style="!marks.length ? 'border-bottom: none;' : ''"
        >
          <h3 class="task-description__subtitle">
            <i class="icon-down-secondary"></i>
            Оценка
          </h3>
          <Button
            class="task-description__button"
            :type="'simple'"
            :icon="'plus'"
            :title="'Поставить'"
            @handleButton="openBlackSphereModal"
          />
        </summary>
        <div class="task-description__body" v-if="marks.length">
          <BlackSphereMarks :marks="marks" :task="currentTask" />
        </div>
      </details>
    </div>
  </div>
</template>

<script>
import EditField from "@/modules/Isp/components/EditField/EditField";
import moment from "moment";
import EditableUser from "@/modules/TaskManager/components/MyTasks/ShowAndEditTask/EditableUser";
import TypeTask from "@/modules/UiKit/components/TypeTask/TypeTask";
import DeadLineComponent from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/statuses/DeadLineComponent";
import DeadLineCalendar from "@/modules/Isp/components/InfoProblem/BodyComponents/ProblemBody/statuses/DeadLineCalendar";
import TaskModule, { TaskMutationTypes } from "@/modules/TaskManager/Model/Task/TaskModule";
import TimeInput from "@/modules/UiKit/components/TimeInput/TimeInput";
import TaskManagerModuleV2, {
  TaskManagerModuleV2MutationTypes
} from "@/modules/TaskManager/store/TaskManagerModuleV2";
import StageModule from "@/modules/TaskManager/Model/Stage/StageModule";
import ProjectModule from "@/modules/TaskManager/Model/Project/ProjectModule";
import { mapGetters } from "vuex";
import UrlMixin from "@/helpers/UrlMixin";
import Button from "@/modules/UiKit/components/buttons/Button";
import CheckList from "@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/CheckList/CheckList";
import FileList from "@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/FilesList/FileList";
import NotificationObserver from "@/helpers/UserNotification";
import taskModule from "@/modules/TaskManager/Model/Task/TaskModule";
import FileUpload from "vue-upload-component";
import Select from "@/modules/UiKit/components/Select/Select";
import ClickOutside from "vue-click-outside";
import DropFileUpload from "@/modules/Messenger/components/Chat/ChatMain/DropFileUpload";
import BlackSphereMarks from "@/modules/TaskManager/components/MyTasks/ShowAndEditTask/BodyComponents/BlackSphereMarks/BlackSphereMarks";
import TaskBlackSphereModule from "@/modules/TaskManager/Model/BlackSphere/BlackSphereModule";
import KanbanModule from "@/modules/TaskManager/Model/Kanban/KanbanModule";

export default {
  name: "DescriptionBodyTask",
  mixins: [UrlMixin],
  props: {
    isOnCurtain: {
      type: Boolean,
      default: true
    },
    isOwner: {
      type: Boolean,
      default: false
    },
    isMember: {
      type: Boolean,
      default: false
    },
    isAuthor: {
      type: Boolean,
      default: false
    },
    isExecutor: {
      type: Boolean,
      default: false
    },
    isParentEditUser: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BlackSphereMarks,
    FileList,
    CheckList,
    Button,
    TimeInput,
    DeadLineCalendar,
    DeadLineComponent,
    TypeTask,
    EditableUser,
    EditField,
    FileUpload,
    Select,
    DropFileUpload
  },
  data() {
    return {
      name: "",
      description: "",
      timePlan: 0,
      timeFact: 0,
      type: null,
      isEditDeadline: false,
      showCalendar: false,
      showDeadlineCalendar: false,
      showConfirmModal: false,
      selectDateStart: "",
      selectDateDeadline: "",
      status: [],
      bigTextTrigger: false,
      contentHeight: 0,
      showCheckListForm: false,
      checkList: [],
      files: [],
      parentStage: [],
      showEditStage: false
    };
  },
  mounted() {
    if (this.currentTask) {
      this.name = this.setName;
      this.description = this.currentTask.description;
      this.description = this.setType;
      this.timePlan = this.currentTask.time_plan;
      this.timeFact = this.currentTask.time_fact;
      this.selectDateStart = this.currentTask.date_from
        ? new Date(this.currentTask.date_from)
        : null;
      this.selectDateDeadline = this.currentTask.date_to
        ? new Date(this.currentTask.date_to)
        : null;
      this.status = this.setStatus;
      this.type = this.setType;
      this.checkList = this.currentTask.checklits;
    }
    this.hideBigText();
    this.contentHeight = this.$refs.taskDescription.offsetHeight;
  },
  methods: {
    handleUploadFile(val) {
      val.forEach((item) => {
        if (item.type === "") {
          item.type = item.name.split(".").slice(-1).join("").toLowerCase();
        }
      });
      TaskModule[TaskMutationTypes.SET_PARAMS_FILES_UPLOAD]("upload");
      taskModule.openModalFileUpload(val);
      this.files = [];
    },
    onPaste(val) {
      if (val.clipboardData.files.length !== 0 && this.totalNumberFiles < 10) {
        TaskModule[TaskMutationTypes.SET_PARAMS_FILES_UPLOAD]("upload");

        Array.from(val.clipboardData.files).forEach((file) => {
          const fileItem = {
            id: file.name + Date.now(),
            file: file,
            name: file.name,
            size: file.size,
            type: file.type,
            fileObject: true,
            postAction: "/upload/post"
          };
          this.files.push(fileItem);
        });

        taskModule.openModalFileUpload(this.files);
        this.files = [];
      }
    },
    hideBigText() {
      this.bigTextTrigger = true;
    },
    showBigText() {
      this.bigTextTrigger = false;
    },
    completeTask() {
      this.$emit("completeTask");
    },
    filesSplice(val) {
      this.allFiles.splice(val, 1);
    },
    setEditModeOfDeadline() {
      this.isEditDeadline = true;
    },
    editDeadlines() {
      this.isEditDeadline = false;
    },
    cancelEditDeadline() {
      this.isEditDeadline = false;
    },
    switchCalendar() {
      this.showCalendar = !this.showCalendar;
      this.showDeadlineCalendar = false;
    },
    switchDeadlineCalendar() {
      this.showDeadlineCalendar = !this.showDeadlineCalendar;
      this.showCalendar = false;
    },
    closeCalendar() {
      this.showCalendar = false;
      this.showDeadlineCalendar = false;
    },
    setIcon(type) {
      if (type === "Задача") {
        return "trip-origin";
      } else if (type === "Подзадача") {
        return "all-out";
      } else if (type === "Улучшение") {
        return "arrow-up";
      } else {
        return "info";
      }
    },
    handleEdit(val) {
      TaskModule.updateCurrentTaskAction({
        id: this.currentTask.id,
        ...val
      });
      KanbanModule.editCurrentEditableTaskAction(val);
    },
    handleEditName(val) {
      this.handleEdit({
        name: val
      });
    },
    editDeadline(deadline) {
      this.selectDateDeadline = deadline;
      if (deadline !== this.currentTask.date_to) {
        this.handleEdit({
          date_to: deadline === null ? null : moment(deadline).format("YYYY-MM-DD")
        });
      }
      this.closeCalendar();
    },
    editDateStart(deadline) {
      if (deadline !== this.currentTask.date_from) {
        this.selectDateStart = deadline === null ? null : moment(deadline).format("YYYY-MM-DD");
        this.selectDateDeadline = this.currentTask.date_to
          ? this.currentTask.date_to
          : this.selectDateStart;
        this.handleEdit({
          date_from: this.selectDateStart,
          date_to: this.selectDateDeadline
        });
      }
      this.closeCalendar();
    },
    editPriority(val) {
      if (val.id !== this.currentTask.priority_id) {
        this.handleEdit({
          priority_id: val.id
        });
      }
    },
    editType(val) {
      if (val.id !== this.currentTask.type_id) {
        this.handleEdit({
          type_id: val.id
        });
      }
    },
    editStatus(val) {
      this.status = this.setStatus;
      if (val.id !== this.currentTask.status_id) {
        if (val.is_end) {
          this.$emit("completeTask");
        } else {
          this.status = [val];
          this.handleEdit({
            status_id: val.id,
            status_name: val.name
          });
        }
      }
    },
    editPlanTime(val) {
      if (val !== this.currentTask.time_plan) {
        this.handleEdit({
          time_plan: val
        });
      }
    },
    editFactTime(val) {
      if (val !== this.currentTask.time_fact) {
        this.handleEdit({
          time_fact: val
        });
      }
    },
    editDescription(val) {
      this.handleEdit({
        description: val
      });
    },
    editExecutor(val) {
      this.handleEdit({
        executor_id: val
      });
    },
    editStage(val) {
      this.handleEdit({
        stage_id: val[0]?.id,
        stage_name: val[0]?.id === null ? "-" : val[0]?.name
      });
      this.showEditStage = false;
    },
    toggleEditStage(val) {
      this.showEditStage = val;
      if (this.showEditStage) {
        StageModule.getStageByProjectId({ project_id: this.currentTask.project_id }).then(() => {
          this.parentStage = this.setStageList.filter(
            (item) => item.id === this.currentTask.stage_id
          );
        });
      }
    },
    getMoreStages() {
      StageModule.getStageByPagination({
        project_id: this.currentTask.project_id
      });
    },
    goToProject() {
      if (this.currentTask.project_id) {
        TaskManagerModuleV2.closeCurtainShowAndEditTask();
        this.$router.push({ name: "Project", params: { id: this.currentTask.project_id } });
      }
    },
    goToStage() {
      if (this.currentTask.stage_id) {
        TaskManagerModuleV2.closeCurtainShowAndEditTask();
        StageModule.goToStagePage({
          project_id: this.currentTask.project_id,
          stage: {
            id: this.currentTask.stage_id,
            name: this.currentTask.stage_name
          }
        });
        TaskModule.getTaskListByStageIdAction({
          stage_id: this.currentTask.stage_id,
          per_page: 10,
          project_id: this.currentTask.project_id,
          toReset: true
        });
        this.$router.push({
          name: "Project",
          params: { id: this.currentTask.project_id },
          query: { stage: `${this.currentTask.stage_id}` }
        });
      }
    },
    toggleShowCheckListForm(val) {
      this.showCheckListForm = val;
      if (this.showCheckListForm) {
        const details = document.getElementById("task-check-list");
        details.setAttribute("open", "open");
        this.$nextTick(() => {
          const input = document.getElementById("check-list");
          input.scrollIntoView(false + 300, { behavior: "smooth" });
          if (input) input.focus();
        });
      }
    },
    handleAccept(val) {
      if (!this.checkListValidation(val)) return false;
      TaskModule.createTaskCheckListAction({
        id: this.currentTask.id,
        check_points: [val]
      });
      this.showCheckListForm = false;
    },
    checkListValidation(val) {
      if (!val.trim().length) {
        NotificationObserver.notification({
          type: "error",
          message: "Пункт не может быть пустым!"
        });
        return false;
      }
      return true;
    },
    openBlackSphereModal() {
      TaskManagerModuleV2[TaskManagerModuleV2MutationTypes.TOGGLE_SHOW_BLACK_SPHERE_MARK_MODAL](
        true
      );
    }
  },
  computed: {
    ...mapGetters("UsersModule", ["userList"]),
    totalNumberFiles() {
      const countFilesUploadCurrentTask = taskModule.filesUploadCurrentTask
        ? taskModule.filesUploadCurrentTask.length
        : 0;
      const countFilesList = taskModule.filesList ? taskModule.filesList.length : 0;
      const countFilesUploadModal = taskModule.filesUploadModal
        ? taskModule.filesUploadModal.length
        : 0;
      return countFilesUploadCurrentTask + countFilesList + countFilesUploadModal;
    },
    isEdit() {
      return this.isOwner || this.isAuthor || this.isExecutor;
    },
    isStageEdit() {
      return (
        (this.isAuthor || this.isOwner) &&
        this.currentTask.project_id &&
        !this.currentTask.parent_id
      );
    },
    currentTask() {
      return TaskModule.currentTask;
    },
    filesList() {
      return TaskModule.filesList;
    },
    isSubTask() {
      return TaskModule.currentTask.parent_id;
    },
    setTaskTitle() {
      return this.currentTask.alias + "-" + this.currentTask.task_id + " " + this.currentTask.name;
    },
    setProjectName() {
      return this.currentTask.project_name ? this.currentTask.project_name : "-";
    },
    setStageName() {
      return this.currentTask.stage_name ? this.currentTask.stage_name : "-";
    },
    setPriorityList() {
      return TaskModule.prioritiesList.length
        ? TaskModule.prioritiesList.map((item) => {
            return { ...item, type: "priority" };
          })
        : [];
    },
    setPriority() {
      return this.setPriorityList.length
        ? this.setPriorityList.find((item) => item.id === this.currentTask.priority_id)
        : [];
    },
    setType() {
      let type =
        this.setTypeList.length &&
        this.setTypeList.find((item) => item.id === this.currentTask.type_id);
      if (this.currentTask.parent_id) {
        type = {
          id: null,
          name: "Подзадача",
          type: "type"
        };
      } else {
        type = {
          id: type.id,
          name: type.name,
          type: "type"
        };
      }
      return type;
    },
    setName() {
      return { name: this.currentTask.name ? this.currentTask.name : "" };
    },
    setDescription() {
      return { description: this.currentTask.description ? this.currentTask.description : "" };
    },
    setTypeList() {
      return TaskModule.typesList.length
        ? TaskModule.typesList.map((item) => {
            return { ...item, type: "type" };
          })
        : [];
    },
    setStatusesList() {
      return TaskModule.statusesList.length
        ? TaskModule.statusesList.map((item) => {
            return { ...item, type: "status" };
          })
        : [];
    },
    setStatus() {
      return this.setStatusesList.length
        ? this.setStatusesList.find((item) => item.id === this.currentTask.status_id)
        : [];
    },
    setToStatuses() {
      const ids =
        this.setStatus && this.setStatus.to_statuses
          ? this.status.to_statuses
          : this.setStatusesList.length
          ? this.setStatusesList
              .filter((item) => item.id !== this.currentTask.status_id)
              .map((item) => item.id)
          : [];
      let toStatuses = [];
      this.setStatusesList.map((item) => {
        ids.forEach((id) => {
          if (id === item.id) {
            toStatuses.push(item);
          }
        });
      });
      return toStatuses;
    },
    isEndStatus() {
      return this.setStatus ? this.setStatus.is_end : false;
    },
    setStylePriority() {
      return this.setPriority.color
        ? `color: #${this.setPriority.color}; border: 2px solid #${this.setPriority.color}`
        : "color: #c3c7d2; border: 2px solid #c3c7d2";
    },
    setDeadlineDate() {
      return this.currentTask && this.currentTask.date_to ? moment(this.currentTask.date_to) : null;
    },
    setStartDate() {
      return this.currentTask && this.currentTask.date_from
        ? moment(this.currentTask.date_from)
        : null;
    },
    setPlanTime() {
      return this.currentTask.time_plan ? this.currentTask.time_plan : "-";
    },
    setFactTime() {
      return this.currentTask.time_fact ? this.currentTask.time_fact : "-";
    },
    setAlias() {
      return this.currentTask.alias ? this.currentTask.alias : "WAP";
    },
    setStageList() {
      const stages = [
        {
          id: null,
          name: "Вне этапа"
        }
      ];
      const stageList =
        this.currentTask.project_id &&
        StageModule.stageList &&
        StageModule.stageList[`project_${this.currentTask.project_id}`]
          ? StageModule.stageList[`project_${this.currentTask.project_id}`].list
          : [];
      stages.push(...stageList);
      return stages;
    },
    stagePaginationLoader() {
      return StageModule.stagePaginationLoader;
    },
    setConfirmParams() {
      if (this.isSubTask) {
        return {
          title: "Выполнить подзадачу",
          description: `Вы уверены, что хотите выполнить подзадачу?`
        };
      } else {
        return {
          title: "Выполнить задачу",
          description: `Задача содержит невыполненные вложенные подзадачи. Вложенные подзадачи выполнятся вместе с ней. Вы уверены, что хотите выполнить задачу?`
        };
      }
    },
    setDescriptionPlaceholder() {
      return this.isSubTask ? "Добавьте описание подзадачи" : "Добавьте описание задачи";
    },
    setRoute() {
      return {
        name: "Task",
        params: { id: this.currentTask.parent_task_id },
        query: {
          alias: this.currentTask.alias
        }
      };
    },
    setAuthor() {
      return this.userList.users.find((item) => item.id === this.currentTask.author_id);
    },
    setExecutor() {
      return this.currentTask.executor_id
        ? this.userList.users.find((item) => item.id === this.currentTask.executor_id)
        : [];
    },
    ownerList() {
      return ProjectModule.ownerList;
    },
    membersList() {
      return ProjectModule.membersList;
    },
    isUsersList() {
      if (this.currentTask.project_id) {
        return this.ownerList
          .map((item) => item.user_info)
          .concat(this.membersList.map((item) => item.user_info))
          .sort((x, y) => {
            if (x.full_name < y.full_name) {
              return -1;
            }
            if (x.full_name > y.full_name) {
              return 1;
            }
            return 0;
          });
      } else {
        return this.userList.users.filter((item) => item.is_active);
      }
    },
    showCheckListButton() {
      return this.currentTask.checklists.length < 50 && (this.isEdit || this.isParentEditUser);
    },
    marks() {
      return TaskBlackSphereModule.marks;
    }
  },
  watch: {
    setStatus() {
      this.status = this.setStatus;
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.task-description {
  details > summary {
    list-style: none;
    outline: none;
    border-top: 1px solid #eae8f0;
    padding: 24px;

    @media (max-width: $sm) {
      padding: 16px;
    }

    i {
      transform: rotate(-90deg);
    }
  }

  details[open] > summary {
    border-bottom: 1px solid #eae8f0;

    i {
      transform: rotate(0);
    }
  }

  &__files {
    .file-uploader {
      &:hover {
        background: transparent;
        border: none;
        box-shadow: none;
        filter: drop-shadow(0px 8px 10px rgba(48, 79, 254, 0.14))
          drop-shadow(0px 3px 14px rgba(48, 79, 254, 0.12))
          drop-shadow(0px 4px 5px rgba(48, 79, 254, 0.2));
        color: #787996;
      }
    }
    /deep/ {
      label {
        cursor: pointer;
      }
    }
  }

  &__header {
    padding: 24px 24px 24px 16px;

    @media (max-width: $sm) {
      padding: 16px;
    }
  }

  &__statuses {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;

    @media (max-width: $sm) {
      flex-wrap: wrap;
    }

    /deep/ {
      .task-type {
        margin-right: 8px;

        @media (max-width: $sm) {
          margin: 0 8px 16px 0;
        }
      }
    }
  }

  &__done {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #323232;

    .checkbox-bg {
      margin-right: 8px;
    }
  }

  &__observe {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #323232;

    .switch-widget {
      margin-left: 8px;
    }
  }

  &__title {
    display: flex;
    flex-wrap: wrap;

    span {
      margin: 0 0 8px;
      padding: 0 0 0 8px;
      white-space: nowrap;
      font-size: 22px;
      font-weight: 900;
      line-height: 26px;
      color: #343748;
    }

    /deep/ {
      .textarea {
        max-width: 100%;
        margin: 0;
      }

      textarea {
        line-height: 22px;
        min-height: 46px;

        @media (max-width: $sm) {
          font-size: 16px;
        }
      }
    }
  }

  &__summary-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__subtitle {
    margin: 0;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #0a1417;
    cursor: pointer;

    @media (max-width: $sm) {
      font-size: 16px;
    }

    i {
      font-size: 6px;
      color: $icon-subdued;
      margin-right: 15px;
    }
  }

  &__body {
    padding: 24px;

    @media (max-width: $sm) {
      padding: 16px;
    }

    .file-list {
      margin-top: 15px;
    }

    /deep/ {
      .drop-active {
        position: relative;
        height: 70px;
        padding: 5px 0;

        &__wrap {
          flex-flow: row;
        }

        .icon-cloud-upload {
          font-size: 50px;
        }

        &__title {
          display: none;
        }

        &__text {
          display: none;
        }
      }
    }

    &_row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      @media (max-width: $md) {
        flex-wrap: nowrap;
      }

      @media (max-width: $sm) {
        flex-wrap: wrap;
      }
    }
  }

  &__dates {
    display: flex;
    padding: 24px 0 16px;
    flex-wrap: wrap;

    /deep/ {
      .deadline {
        display: flex;
        flex-direction: column;
        padding-left: 8px;

        @media (max-width: $xxxl) {
          width: 50%;
          margin: 0 0 24px;
        }
      }

      .date-picker__value {
        width: 123px;
        border-radius: 4px;
      }

      .date-picker__calendar {
        padding: 24px 24px 0;

        @media (max-width: $md) {
          padding: 24px 16px 0;
        }

        @media (max-width: $sm) {
          padding: 24px 0 0;
        }
      }

      .date-picker__calendar .vc-arrows-container {
        width: 100%;
      }

      .label {
        margin: 0;
      }

      .label label {
        margin: 0 8px 8px 0;
        text-transform: none;
        font-weight: 500;
        font-size: 12px;
        line-height: 19px;
        color: $text-subdued;
      }

      .time-input__wrap {
        max-width: 130px;
        height: 30px;
        border-radius: 4px;
      }

      .time-input__input {
        max-width: 100px;
        width: max-content;
        max-height: 30px;
        min-height: 30px;
        padding: 4px 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #323232;
      }

      .task-description__label {
        margin: 0 8px 8px 0;
        text-transform: none;
        white-space: nowrap;
        font-weight: 500;
        font-size: 12px;
        line-height: 19px;
        color: $text-subdued;
      }
    }
  }

  &__deadlines {
    /deep/ {
      .date-picker__calendar .vc-arrows-container {
        @media (max-width: $sm) {
          width: 260px;
        }
      }
    }
  }

  &__row {
    @media (max-width: $sm) {
      width: 100%;
    }

    &_first {
      z-index: 3;
    }

    &_second {
      z-index: 2;
    }

    &_last {
      z-index: 1;
    }
  }

  &__caption {
    display: flex;
    align-items: center;
    margin: 0 0 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #000000;

    &:hover {
      .icon-edit {
        opacity: 1;
      }
    }
    @media (max-width: $sm) {
      font-size: 14px;
    }
    .icon-edit {
      opacity: 0;
      @media (max-width: $md) {
        opacity: 1;
      }
    }
  }

  &__link {
    max-width: 95%;
    width: 100%;
    word-break: break-word;
    padding-left: 8px;

    &_parent-name {
      margin-top: 16px;
    }
  }

  &__label {
    display: flex;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #343748;
    margin-bottom: 16px;
  }

  &__users {
    .task-description__body {
      padding-top: 0;
      flex-wrap: wrap;
    }

    /deep/ {
      .label {
        margin-bottom: 0;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        color: $text-default;
      }

      .task-description__owner {
        margin-top: 24px;
      }

      .icon-edit {
        @media (max-width: $md) {
          opacity: 1;
        }
      }

      .editable-user__select {
        margin-bottom: 0;
      }
    }
  }

  /deep/ {
    .date-picker__calendar {
      padding: 16px 0 0;
    }
  }

  &__wrap {
    margin-bottom: 24px;

    a {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #5551f9;

      @media (max-width: $sm) {
        font-size: 14px;
      }
    }

    /deep/ {
      textarea {
        @media (max-width: $sm) {
          font-size: 14px;
        }
      }
    }

    &_description {
      /deep/ .textarea {
        width: 100%;
      }
    }
  }

  &__docs {
    &-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;

      @media (max-width: $sm) {
        flex-wrap: wrap;
      }
    }
  }

  &__owner {
    /deep/ {
      .editable-user__wrap {
        margin-right: 56px;
      }
    }
  }

  &__task-description {
    margin: 0 0 16px;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: $text-default;
    word-break: break-word;

    @media (max-width: $xxxl) {
      font-size: 14px;
      line-height: 20px;
    }

    /deep/ a {
      color: $link-default;

      &:hover {
        color: $link-hovered;
      }

      &:active {
        color: $link-pressed;
      }
    }
  }

  &__button {
    padding: 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: $link-default;
    background: transparent;
    border: none;
    outline: none;

    &_edit {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $icon-subdued;
      margin-left: 8px;

      i {
        font-size: 18px;
      }
    }

    @media (max-width: $xxxl) {
      font-size: 14px;
      line-height: 20px;
    }

    &:active {
      color: $link-pressed;
    }
  }
}

.link {
  color: $link-default;
  cursor: pointer;

  &:hover {
    color: $link-hovered;
  }
}

/deep/ {
  .aop-button {
    span {
      @media (max-width: $sm) {
        display: none;
      }
    }
  }

  .user {
    @media (max-width: $xxxl) {
      align-items: flex-start;
    }
  }

  .user__main-info {
    @media (max-width: $xxxl) {
      flex-wrap: wrap;
    }
  }

  .user__name,
  .user__description {
    @media (max-width: $xxxl) {
      max-width: 300px;
    }

    @media (max-width: $md) {
      max-width: 400px;
    }

    @media (max-width: $sm) {
      max-width: 260px;
    }

    @media (max-width: $xxs) {
      max-width: 200px;
    }
  }
}
</style>
